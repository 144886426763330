<template>
  <v-card-actions class="justify-space-between">
    <v-btn
      class="text-capitalize font-weight-bold"
      color="primary"
      text
    >
      Buy Now
    </v-btn>
    <v-hover v-slot="{ hover }">
      <v-btn
        :color="(hover || liked) ? 'red' : 'grey'"
        class="d-flex align-center"
        text
        @click.prevent="toggleFavourited"
      >
        <v-icon left>{{ (hover || liked) ? 'mdi-heart' : 'mdi-heart-outline' }}</v-icon>
        <p class="body-2 mb-0 body-2">{{ likeCount }}</p>
      </v-btn>
    </v-hover>
  </v-card-actions>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import NFTCard from './NFTCard';

export default {
  name: 'NFTCardActions',
  mixins: [NFTCard],
  data: () => ({
    isFavourited: false,
  }),
  computed: {
    ...mapGetters({
      token: 'user/token',
    }),
    favouritesCount() {
      return 0;
    },
  },
  methods: {
    ...mapActions({ likeNFT: 'nfts/likeNFT', unlikeNFT: 'nfts/unlikeNFT' }),
    toggleFavourited() {
      if (this.liked) {
        this.unlikeNFT({ itemID: this.publicID, token: this.token });
        console.log('unlike');
      } else {
        this.likeNFT({ itemID: this.publicID, token: this.token });
        console.log('like');
      }
    },
  },
};
</script>
